<template>
    <!-- eslint-disable max-len -->
    <svg viewBox="0 0 24 25"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M2.46138 12.0172H21.5783V21.2018H2.46138V12.0172Z"
              fill="#544F57" />
        <path d="M2.46138 11.6741H21.5783V13.9728H2.46138V11.6741Z"
              fill="#454045" />
        <path d="M2.46138 12.0172H4.03193V20.3289H2.46138V12.0172Z"
              fill="#454045" />
        <path d="M20.0078 12.0172H21.5783V20.3289H20.0078V12.0172Z"
              fill="#454045" />
        <path d="M20.0127 2.08743H4.0271C3.1624 2.08743 2.46138 2.64725 2.46138 3.33788V9.86851H21.5783V3.33788C21.5783 2.64729 20.8774 2.08743 20.0127 2.08743Z"
              fill="#454045" />
        <path d="M21.8214 11.6741H2.21844C2.01637 11.6741 1.85255 11.5184 1.85255 11.3263V3.35542C1.8526 2.32209 2.73693 1.48145 3.82396 1.48145H20.2159C21.3029 1.48145 22.1872 2.32209 22.1872 3.35542V11.3263C22.1872 11.5184 22.0235 11.6741 21.8214 11.6741ZM2.58432 10.9785H21.4555V3.35542C21.4555 2.70563 20.8994 2.17702 20.2159 2.17702H3.82396C3.14041 2.17702 2.58432 2.70563 2.58432 3.35542V10.9785Z"
              fill="#E7A52E" />
        <path d="M21.3945 11.6741H2.64528C2.45201 11.6741 2.29533 11.5255 2.29533 11.3422V3.73654C2.29538 2.75055 3.1412 1.94843 4.18089 1.94843H19.8589C20.8986 1.94843 21.7444 2.75055 21.7444 3.73654V11.3422C21.7444 11.5255 21.5878 11.6741 21.3945 11.6741ZM2.99523 11.0104H21.0446V3.73654C21.0446 3.11652 20.5127 2.61213 19.8589 2.61213H4.18089C3.5271 2.61213 2.99523 3.11652 2.99523 3.73654V11.0104Z"
              fill="#E7A52E" />
        <path d="M22.8538 20.672L1.18596 20.672L1.1859 22.4931C1.1859 23.1975 1.75693 23.7686 2.46137 23.7686H21.5783C22.2827 23.7686 22.8538 23.1976 22.8538 22.4931V20.672Z"
              fill="#E7A52E" />
        <path d="M12.0107 17.4647C11.5946 17.4647 11.1784 17.348 10.8233 17.1147L8.40623 15.5267C7.69602 15.0601 7.40112 14.2393 7.67238 13.4844L8.59565 10.9149C8.86691 10.1599 9.63902 9.65271 10.5169 9.65271H13.5046C14.3824 9.65271 15.1545 10.1599 15.4258 10.9149L16.3491 13.4844C16.6203 14.2394 16.3254 15.0601 15.6152 15.5267L13.1981 17.1147C12.843 17.348 12.4269 17.4647 12.0107 17.4647ZM10.5169 10.3663C9.98197 10.3663 9.51145 10.6754 9.34614 11.1355L8.42287 13.7049C8.25757 14.1649 8.43723 14.6651 8.87002 14.9494L11.2871 16.5375C11.72 16.8218 12.3014 16.8218 12.7343 16.5374L15.1514 14.9494C15.5841 14.6651 15.7638 14.165 15.5985 13.7049L14.6752 11.1355C14.5099 10.6754 14.0394 10.3663 13.5045 10.3663L10.5169 10.3663Z"
              fill="#454045" />
        <path d="M19.7876 9.2834C19.7166 9.2834 19.6451 9.2886 19.574 9.29863C19.6455 9.11816 19.6809 8.92719 19.6809 8.73712C19.6809 8.31074 19.5034 7.88699 19.1568 7.5853C18.8678 7.33372 18.5107 7.21034 18.1559 7.21034C17.9052 7.21034 17.6549 7.2718 17.4304 7.39302C17.4309 7.37689 17.4313 7.36077 17.4313 7.34464C17.4313 6.75083 17.0825 6.18626 16.5018 5.93951C16.307 5.85668 16.1047 5.81745 15.9054 5.81745C15.5004 5.81745 15.1089 5.97964 14.8229 6.26736C14.7462 5.97612 14.5845 5.7154 14.363 5.51749C14.1415 5.31912 13.8599 5.18355 13.5425 5.14254C13.4762 5.13382 13.4104 5.12988 13.345 5.12988C12.7904 5.12988 12.2891 5.43199 12.0227 5.8976C11.7559 5.43199 11.2545 5.12857 10.6995 5.12857C10.6345 5.12857 10.5691 5.13293 10.5033 5.14123C10.1863 5.18177 9.90429 5.31691 9.68239 5.51487C9.46048 5.71282 9.29871 5.9735 9.22156 6.26473C8.9351 5.97654 8.5436 5.81393 8.13818 5.81393C7.9398 5.81393 7.73796 5.85275 7.54352 5.93515C6.96194 6.18148 6.6123 6.74694 6.6123 7.34117C6.6123 7.35688 6.61272 7.37211 6.61319 7.38781C6.38823 7.26617 6.13755 7.20425 5.88644 7.20425C5.53196 7.20425 5.17581 7.3272 4.88673 7.57789C4.53924 7.87958 4.36093 8.30422 4.36093 8.73102C4.36093 8.9211 4.39623 9.11118 4.46729 9.29127C3.83602 9.20015 3.18989 9.51449 2.88731 10.1161L7.56227 10.1188L21.1528 10.1257C20.885 9.59207 20.3465 9.2834 19.7876 9.2834Z"
              fill="#FFF885" />
        <path d="M12.0224 5.89763C12.0224 5.89763 10.306 5.83834 10.5746 7.51421C10.5746 7.51421 9.00684 7.42135 9.14371 8.94507C9.21519 9.7381 8.37946 10.0254 7.56201 10.1187L2.88707 10.1161C3.18965 9.51442 3.83573 9.20007 4.46704 9.2912C4.39598 9.11115 4.36068 8.92107 4.36068 8.73095C4.36068 8.30415 4.53899 7.87951 4.88648 7.57782C5.17551 7.32713 5.53171 7.20418 5.88618 7.20418C6.13729 7.20418 6.38798 7.2661 6.61293 7.38774C6.61251 7.37204 6.61204 7.35681 6.61204 7.3411C6.61204 6.74687 6.96168 6.18142 7.54326 5.93509C7.73769 5.85268 7.93954 5.81387 8.13791 5.81387C8.54338 5.81387 8.93488 5.97648 9.22129 6.26467C9.29844 5.97343 9.46021 5.71271 9.68212 5.51481C9.90402 5.3169 10.1861 5.18171 10.503 5.14117C10.5689 5.13287 10.6343 5.12851 10.6992 5.12851C11.2543 5.12856 11.7556 5.43203 12.0224 5.89763Z"
              fill="#FFDF1B" />
        <path d="M1.18597 11.674L22.8538 11.674L22.8539 9.85293C22.8539 9.14853 22.2828 8.57745 21.5784 8.57745L2.46144 8.57745C1.75705 8.57745 1.18597 9.14849 1.18597 9.85293L1.18597 11.674Z"
              fill="#E7A52E" />
        <path d="M3.49388 21.0586L3.49388 9.95563C3.49388 9.54652 3.19244 9.21484 2.82057 9.21484L1.85923 9.21484C1.48739 9.21484 1.18592 9.54649 1.18592 9.95563L1.18592 21.0586C1.18592 21.4677 1.48736 21.7993 1.85923 21.7993L2.82057 21.7993C3.19244 21.7993 3.49388 21.4677 3.49388 21.0586Z"
              fill="#E7A52E" />
        <path d="M22.8538 21.0586L22.8538 9.95563C22.8538 9.54652 22.5524 9.21484 22.1805 9.21484L21.2192 9.21484C20.8473 9.21484 20.5459 9.54649 20.5459 9.95563L20.5459 21.0586C20.5459 21.4677 20.8473 21.7993 21.2192 21.7993L22.1805 21.7993C22.5524 21.7993 22.8538 21.4677 22.8538 21.0586Z"
              fill="#E7A52E" />
        <path d="M12.8838 16.6851L15.0695 15.0971C15.5847 14.7228 15.8003 14.0593 15.6035 13.4537L14.7686 10.8842C14.5718 10.2786 14.0075 9.86853 13.3707 9.86853H10.669C10.0322 9.86853 9.46782 10.2786 9.27104 10.8842L8.43615 13.4537C8.23936 14.0593 8.45494 14.7228 8.97015 15.0971L11.1558 16.6851C11.671 17.0594 12.3686 17.0594 12.8838 16.6851Z"
              fill="#F4DD45" />
        <path d="M12.0199 17.3237C11.6436 17.3237 11.2672 17.2071 10.9461 16.9738L8.76041 15.3858C8.11818 14.9191 7.8515 14.0984 8.0968 13.3434L8.93169 10.774C9.17699 10.019 9.87519 9.51178 10.669 9.51178H13.3707C14.1645 9.51178 14.8627 10.019 15.108 10.774L15.9429 13.3434C16.1882 14.0985 15.9215 14.9192 15.2793 15.3858L13.0936 16.9738C12.7725 17.2071 12.3962 17.3237 12.0199 17.3237ZM10.669 10.2254C10.1853 10.2254 9.75983 10.5345 9.61035 10.9945L8.77546 13.564C8.62597 14.024 8.78844 14.5241 9.1798 14.8085L11.3655 16.3965C11.7569 16.6808 12.2827 16.6808 12.6741 16.3965L14.8599 14.8085C15.2512 14.5242 15.4137 14.0241 15.2642 13.564L14.4293 10.9946C14.2798 10.5345 13.8544 10.2254 13.3707 10.2254L10.669 10.2254Z"
              fill="#C67214" />
        <path d="M13.332 14.7125L12.5365 13.3346C12.8847 13.1332 13.119 12.757 13.119 12.3258C13.119 11.6823 12.5974 11.1608 11.954 11.1608C11.3105 11.1608 10.789 11.6823 10.789 12.3258C10.789 12.757 11.0234 13.1332 11.3715 13.3346L10.576 14.7125H13.332Z"
              fill="#454045" />
        <rect x="9.87694"
              y="1.01257"
              width="4.2262"
              height="1.94812"
              rx="0.1"
              fill="#F4DD45" />
    </svg>
</template>
<script setup lang="ts">
</script>
